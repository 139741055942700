import styled from "styled-components"

export const Wrapper = styled.div`
  width: 100%;
  overflow-y: scroll;
`

// export const Container = styled.div`
//   padding-left: calc(50% - 580px);
//   padding-right: calc(50% - 580px);
// `
