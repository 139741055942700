import * as S from './Appstyle';
import { GlobalStyled } from './GlobalAppStyle';
import Header from './components/header/header';
import Content from './components/content/content';

function App() {
  return (
    <S.Wrapper>
      <Header />
      <Content />

      <GlobalStyled />
    </S.Wrapper>
  );
}

export default App;
